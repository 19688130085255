<template>
  <div
    class="flex items-center justify-between py-3"
    :class="[
      transparent ? 'bg-transparent' : 'bg-white',
      noXPadding ? 'px-0' : 'px-4 sm:px-6',
      !grid ? 'border-t' : '',
    ]"
  >
    <div class="flex flex-1 justify-between sm:hidden">
      <button
        @click="changePage(currentPage - 1)"
        class="relative inline-flex items-center rounded-md border bg-white px-4 py-2 text-sm leading-base font-medium text-font-light hover:bg-light-background outline-accent-color cursor-pointer select-none"
      >
        Previous
      </button>
      <button
        @click="changePage(currentPage + 1)"
        class="relative ml-3 inline-flex items-center rounded-md border bg-white px-4 py-2 text-sm leading-base font-medium text-font-light hover:bg-light-background outline-accent-color cursor-pointer select-none"
      >
        Next
      </button>
    </div>

    <!-- INFO -->
    <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
      <div>
        <p class="text-sm text-font-primary cursor-pointer">
          Showing {{ paginationFrom }} to {{ paginationTo }} of
          {{ totalItems }} results
          <span v-if="totalValue || totalValue === 0" class="font-bold">
            Total: {{ useFormatPrice(totalValue) }}
          </span>
          <span v-if="balance" class="font-bold">
            Balance: {{ useFormatPrice(balance) }}
          </span>
          <span> | Per page:</span>
          <select
            @change="selectChangeHandler"
            class="border-white focus:border-white focus:outline-none rounded-md"
            :class="[transparent ? 'bg-transparent' : 'bg-white']"
          >
            <template v-if="!grid">
              <option :selected="perPage === 10" value="10">10</option>
              <option :selected="perPage === 15" value="15">15</option>
              <option :selected="perPage === 20" value="20">20</option>
              <option :selected="perPage === 25" value="25">25</option>
              <option :selected="perPage === 30" value="30">30</option>
              <option :selected="perPage === 100" value="100">100</option>
            </template>

            <template v-else>
              <option :selected="perPage === 12" value="12">12</option>
              <option :selected="perPage === 24" value="24">24</option>
              <option :selected="perPage === 36" value="36">36</option>
            </template>
          </select>
        </p>
      </div>

      <!-- PAGINATION -->
      <div>
        <nav
          class="isolate inline-flex -space-x-px rounded-md h-[38px]"
          aria-label="Pagination"
        >
          <button
            @click="changePage(currentPage - 1)"
            class="relative inline-flex items-center rounded-l-md border bg-white px-2.5 py-2.5 text-sm leading-base font-medium text-font-light hover:bg-light-background focus:z-20 outline-accent-color w-[38px] cursor-pointer select-none"
          >
            <span class="sr-only">Previous</span>
            <ChevronLeftIcon
              class="h-[14px] w-[14px] stroke-font-light mx-auto"
              aria-hidden="true"
              style="stroke-width: 3.5"
            />
          </button>

          <button
            class="bg-white border text-font-light relative inline-flex items-center px-4 py-2 text-sm leading-base font-medium outline-accent-color cursor-pointer select-none"
            @click.prevent="changePage(1)"
            v-if="hasFirst()"
          >
            1
          </button>
          <span
            class="relative inline-flex items-center px-4 py-2 border bg-white text-sm leading-base font-medium text-font-light outline-accent-color"
            v-if="hasFirst()"
            >...</span
          >

          <button
            v-for="(page, index) in calculatedPages"
            :key="index"
            class="bg-white border text-font-light relative inline-flex items-center px-4 py-2 text-sm leading-base font-medium outline-accent-color cursor-pointer select-none"
            :class="{
              [`!z-10 !bg-primary-grey-background !text-font-primary !${borderActiveColor} !${borderTextActiveColor}`]:
                currentPage == page,
            }"
            @click.prevent="changePage(page)"
          >
            {{ page }}
          </button>
          <span
            class="relative inline-flex items-center px-4 py-2 border bg-white text-sm leading-base font-medium text-font-light outline-accent-color"
            v-if="hasLast()"
            >...</span
          >
          <button
            class="bg-white border text-font-light relative inline-flex items-center px-4 py-2 text-sm leading-base font-medium outline-accent-color cursor-pointer select-none"
            v-if="hasLast()"
            @click.prevent="changePage(availablePages)"
          >
            {{ availablePages }}
          </button>

          <span
            class="relative inline-flex items-center px-4 py-2 border bg-white text-sm leading-base font-medium text-font-light"
            v-if="availablePages < totalPages"
            >...</span
          >

          <button
            @click="changePage(currentPage + 1)"
            class="relative inline-flex items-center rounded-r-md border bg-white text-sm leading-base font-medium text-font-light hover:bg-light-background focus:z-20 outline-accent-color w-[38px] px-2.5 py-2.5 cursor-pointer select-none"
            :class="{
              '!cursor-not-allowed bg-gray-200 hover:bg-gray-300': isLastPage,
            }"
          >
            <span class="sr-only">Next</span>
            <ChevronRightIcon
              class="h-[14px] w-[14px] stroke-font-light mx-auto"
              aria-hidden="true"
              style="stroke-width: 3.5"
            />
          </button>
        </nav>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/vue/outline";

const props = defineProps({
  totalItems: { type: Number },
  perPage: { type: Number },
  currentPage: { type: Number },
  pageRange: {
    type: Number,
    default: 1,
  },
  availableItems: { type: Number },
  borderActiveColor: {
    type: String,
    default: "border-indigo-500",
  },
  borderTextActiveColor: {
    type: String,
    default: "text-indigo-500",
  },
  totalValue: {
    type: Number,
  },
  balance: {
    type: Number,
  },
  transparent: {
    type: Boolean,
    default: false,
  },
  noXPadding: {
    type: Boolean,
    default: false,
  },
  grid: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  "load-items",
  "update:current-page",
  "update:per-page",
]);

const totalPages = Math.ceil(props.totalItems / props.perPage);
const availablePages = Math.ceil(props.availableItems / props.perPage);

const paginationFrom = computed(
  () => (props.currentPage - 1) * props.perPage + 1
);

const paginationTo = computed(() => {
  const end = props.currentPage * props.perPage;

  return props.totalItems < end ? props.totalItems : end;
});

function selectChangeHandler(e) {
  emit("update:current-page", 1);
  emit("update:per-page", +e.target.value);
}

function changePage(page: number) {
  if (page > totalPages || page < 1) return;

  // change page
  if (page < availablePages || page === totalPages) {
    emit("update:current-page", page);
  }

  // upload items with last number btn click
  if (page === availablePages) {
    emit("load-items");
    emit("update:current-page", page);
  }

  // upload items with arrow right btn click
  if (page > availablePages) {
    emit("load-items");
    emit("update:current-page", page);
  }
}

const rangeStart = computed(() => {
  const start = props.currentPage - props.pageRange;

  return start > 0 ? start : 1;
});

const rangeEnd = computed(() => {
  const end = props.currentPage + props.pageRange;

  return end < availablePages ? end : availablePages;
});

const isLastPage = computed(() => props.currentPage >= totalPages);

const hasFirst = () => {
  return rangeStart.value !== 1;
};

const hasLast = () => {
  return rangeEnd.value < availablePages;
};

const calculatedPages = computed(() => {
  const pages = [];

  for (let i = rangeStart.value; i <= rangeEnd.value; i++) {
    pages.push(i);
  }

  return pages;
});

onMounted(() => {
  if (props.currentPage === availablePages && availablePages <= totalPages) {
    emit("load-items");
    emit("update:current-page", props.currentPage);
  }

  if (props.currentPage > availablePages && availablePages <= totalPages) {
    emit("load-items");
    emit("update:current-page", props.currentPage);
  }
});
</script>
